import React from "react"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { graphql } from "gatsby"

import Seo from "../components/seo"
import Layout from "../components/layout"
import News from "../components/news"

import { linkResolver } from "../utils/linkResolver"

const NewsPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="News" />
      <div className="pt-8 sm:pt-12 md:pt-18 pb-18">
        <News allPrismicPage={data.allPrismicPage} />
      </div>
    </Layout>
  )
}

export default withPrismicPreview(NewsPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
    linkResolver,
  },
])

export const query = graphql`
  query NewsPageQuery {
    allPrismicPage(sort: { fields: data___date, order: DESC }) {
      nodes {
        uid
        _previewable
        data {
          heading
          excerpt_heading
          excerpt {
            html
          }
          link {
            url
            target
          }
          thumbnail {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
